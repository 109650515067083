import React from "react";
import {Button, ListItem, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axios from "axios";
import cookie from "react-cookies";
import history from "./History";
import https from "https";
import {List} from "@mui/icons-material";

const API_URL =  global.AppConfig.HOST+'/response/csr';

const Input = styled('input')({
    display: 'none',
});

const errorMessageMap = new Map();
errorMessageMap.set('Network Error', '连接业务服务器失败，请联系管理员检查业务服务是否正常运行');

class CSRVerify extends React.Component {
    state = {
        csr: "",
        rows: []
    }

    handleChange = (event) => {
        this.setState({[event.target.id] :event.target.value});
    };

    handleClick = () => {
        console.log("csr: " + this.state.csr)
        axios.post(API_URL, this.state.csr, { headers:{"Access-Control-Allow-Origin": true, "Authorization":"Bearer "+cookie.load("token")}, httpsAgent: new https.Agent({rejectUnauthorized: false})
        }).then(response => {
            this.setState({rows: response.data})
        }).catch(
            err => {
                this.setState({snackbarOpen:true, message: err.message + ': ' + errorMessageMap.get(err.message)});
                console.log(err.message);
                if (err.response.status === 403) {
                    cookie.remove("token");
                    history.push("/");
                    history.go();
                }
            }
        );
    }

    render() {
        return (
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="csr"
                        label="CSR"
                        multiline
                        maxRows={20}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => {alert('clicked')}}
                        >
                        验证
                    </Button>
                </div>

                <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="contained-button-file">
                        <Input accept="*" id="contained-button-file" multiple type="file" />
                        <Button variant="contained" component="span">
                            上传CSR
                        </Button>
                    </label>

                </Stack>
                <List>
                    <ListItem>
                        O: {this.state.rows}
                    </ListItem>
                </List>
            </Box>
        );
    }
}
export default CSRVerify;