import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import renderCellExpand from "./RenderCellExpand";


const rows: GridRowsProp = {

}
const columns: GridColDef[] = [
    { field: 'id', headerName: '传输ID', width: 100},
    { field: 'time', headerName: '请求接收时间', width: 200},
    { field: 'showID', headerName: '影片id', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'playerID', headerName: '播放器id', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'Timestamp', headerName: 'UTC时间戳', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'Signature', headerName: '签名', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'state', headerName: '状态', width: 150 },
    { field: 'code', headerName: '错误代码', width: 150 },
    { field: 'msg', headerName: '错误文字描述', width: 150, sortable: false, renderCell: renderCellExpand },
    { field: 'kdm', headerName: '授权KDM', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'request', headerName: '原始请求', width: 200, sortable: false, renderCell: renderCellExpand },
    { field: 'reply', headerName: '原始回复', width: 200, sortable: false, renderCell: renderCellExpand }
];

export default function MovieAuthorization() {
    return (
        <div style={{width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
            />

        </div>
    );
}