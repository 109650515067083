import React from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import renderCellExpand from "./RenderCellExpand";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import cookie from "react-cookies";
import https from "https";
import history from "./History";

const API_URL =  global.AppConfig.HOST+'/response/device?vendorId=';

const columns: GridColDef[] = [
    { field: 'id', headerName: '序号', width: 100},
    { field: 'devicesn', headerName: '设备唯一标识id', width: 400, renderCell: renderCellExpand},
    { field: 'csr', headerName: 'CSR', width: 700, sortable: false, renderCell: renderCellExpand},
    { field: 'registertime', headerName: '注册时间', width: 200},
];

const errorMessageMap = new Map();
errorMessageMap.set('Network Error', '连接业务服务器失败，请联系管理员检查业务服务是否正常运行');


class DeviceManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: []
        }
    }

    componentDidMount() {
        axios.get(API_URL + cookie.load("vendorId"), { headers:{
                "Access-Control-Allow-Origin": true,
                "Authorization":"Bearer "+cookie.load("token")
            }, httpsAgent: new https.Agent({rejectUnauthorized: false})
        }).then(response => {
            this.setState({rows: response.data})
        }).catch(
            err => {
                this.setState({snackbarOpen:true, message: err.message + ': ' + errorMessageMap.get(err.message)});
                console.log("Network Error:" + err.message);
                console.log("Error.response.status:" + err.response.status);
                console.log(JSON.stringify(err));
                if (err.response.status === 403) {
                    cookie.remove("token");
                    history.push("/");
                    history.go();
                }
            }
        );
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false });
    };

    render() {
        return (
            <div>
                <DataGrid
                    columns={columns}
                    rows={this.state.rows}
                    autoHeight
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={6000}
                    open={this.state.snackbarOpen}
                    onClose={this.handleCloseSnackbar} message={this.state.message}
                />
            </div>
        );
    }
}
export default DeviceManagement;