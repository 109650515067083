import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import renderCellExpand from "./RenderCellExpand";
import axios from 'axios';
import * as https from "https";
import Snackbar from '@mui/material/Snackbar';
import cookie from "react-cookies";
import history from "./History";

const API_URL =  global.AppConfig.HOST+'/response/unknownmsg';

const columns: GridColDef[] = [
    { field: 'id', headerName: '报文序号', width: 100},
    { field: 'timestamp', headerName: '时间', width: 200},
    { field: 'deviceipaddress', headerName: '设备IP地址', width: 300, sortable: false, renderCell: renderCellExpand},
    { field: 'msgtype', headerName: '报文类型', width: 150},
    { field: 'requestmsg', headerName: '原始请求报文', width: 700 , renderCell: renderCellExpand},
    { field: 'requestmsgparsed', headerName: '解析后请求报文', width: 150, sortable: false, renderCell: renderCellExpand },
    { field: 'processresult', headerName: 'ProcessResult', width: 700, sortable: false, renderCell: renderCellExpand}
];
const errorMessageMap = new Map();
errorMessageMap.set('Network Error', '连接业务服务器失败，请联系管理员检查业务服务是否正常运行');

class UnknownMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [{'id':'','deviceipaddress':'正在读取数据...'}],
            snackbarOpen: false,
            message: '',
            errMsg: '',
        }
    }

    componentDidMount() {
        axios.get(API_URL, { headers:{"Access-Control-Allow-Origin": true, "Authorization":"Bearer "+cookie.load("token")}, httpsAgent: new https.Agent({rejectUnauthorized: false})
        }).then(response => {
            this.setState({rows: response.data})
        }).catch(
            err => {
                this.setState({snackbarOpen:true, message: err.message + ': ' + errorMessageMap.get(err.message)});
                console.log(err.message);
                if (err.response.status === 403) {
                    cookie.remove("token");
                    history.push("/");
                    history.go();
                }
            }
        );
    }
    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false });
    };

    render() {
        console.log("未知厂商报文返回:" + JSON.stringify(this.state.rows))
        return (
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={this.state.rows}
                    columns={columns}
                    autoHeight
                />
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
                open={this.state.snackbarOpen}
                onClose={this.handleCloseSnackbar} message={this.state.message}
              />

            </div>
        );
    }
}
export default UnknownMsg;

