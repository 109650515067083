import React, {Component} from "react";
import {Button, TextField} from "@mui/material";
import axios from "axios";
import history from './History';
import cookie from "react-cookies";
import md5 from "md5"


export default class Login extends Component {
    state = {
        vendorId: "",
        password: ""
    };


    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handlePassword = event => {
        this.setState({password: md5(event.target.value)})
    }

    handleClick = () => {
        console.log("vendorId: " + this.state.vendorId)
        console.log("password: " +  this.state.password)
        axios.post(global.AppConfig.HOST + '/login', this.state)
            .then(response => {
                    console.log(response.data);
                    cookie.save("token", response.data);
                    cookie.save("vendorId", this.state.vendorId)
                    history.push("/tabs");
                    history.go();
                }
            )
    }

    render() {
        return (
            <div class="background">
                <div className="Login">

                    <form className="form">
                        <TextField
                            label="厂商ID"
                            id="vendorId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={this.handleChange}
                        /><br/>
                        <p/>
                        <p/>
                        <p/>
                        <TextField
                            label="密码"
                            id="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={this.handlePassword}
                            type={"password"}
                        /><br/>
                        <p/>
                        <p/>
                        <p/>

                        <Button variant="outlined" color="primary" onClick={this.handleClick}>
                            登录
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}