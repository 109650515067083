import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import renderCellExpand from "./RenderCellExpand";

const rows: GridRowsProp = {

}

const columns: GridColDef[] = [
    { field: 'id', headerName: '传输ID', width: 100},
    { field: 'time', headerName: '请求接收时间', width: 200},
    { field: 'showID', headerName: '影片id', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'state', headerName: '状态', width: 150 },
    { field: 'code', headerName: '错误代码', width: 150 },
    { field: 'msg', headerName: '错误文字描述', width: 150, sortable: false, renderCell: renderCellExpand },
    { field: 'title', headerName: '电影名称', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'original_tiles', headerName: '原始电影名称', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'directors', headerName: '导演', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'casts', headerName: '演员', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'genres', headerName: '类型', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'countries', headerName: '国家', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'aka', headerName: '又名', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'year', headerName: '上映年份', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'summary', headerName: '影片介绍', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'duration', headerName: '时长(秒)', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'is3D', headerName: '是否为3D', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'image', headerName: '海报', width: 200, sortable: false, renderCell: renderCellExpand},
    { field: 'request', headerName: '原始请求', width: 200, sortable: false, renderCell: renderCellExpand },
    { field: 'reply', headerName: '原始回复', width: 200, sortable: false, renderCell: renderCellExpand }
];


export default function MovieInfo() {
    return (
        <div style={{width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
            />

        </div>
    );
}