import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Register from "./Register";
import MovieAuthorization from "./MovieAuthorization";
import MovieInfo from "./MovieInfo";
import UnknownMsg from "./UnknownMsg";
import VendorMsg from "./VendorMsg";
import cookie from "react-cookies";
import DeviceManagement from "./DeviceManagement";
import CSRVerify from "./CSRVerify";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="厂商报文" {...a11yProps(0)} />
                    <Tab label="未识别厂商报文" {...a11yProps(1)} />
                    <Tab label="设备管理" {...a11yProps(2)} />
                    <Tab label="CSR验证" {...a11yProps(3)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <h2>识别厂商报文</h2>
                <VendorMsg />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <h2>未识别厂商报文</h2>
                <UnknownMsg />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <h2>设备管理</h2>
                <DeviceManagement />
            </TabPanel>
        </Box>
    );
}
//

//             <TabPanel value={value} index={3}>
//                 <h2>CSR验证</h2>
//                 <CSRVerify />
//             </TabPanel>
//             <h2>Token: {cookie.load("token")}</h2>
//         </Box>
//     );
// }

//<TabPanel value={value} index={0}>
//                 <h2>设备注册记录</h2>
//                 <Register />
//             </TabPanel>
//             <TabPanel value={value} index={1}>
//                 <h2>获取授权记录</h2>
//                 <MovieAuthorization />
//             </TabPanel>
//             <TabPanel value={value} index={2}>
//                 <h2>测试影片信息</h2>
//                 <MovieInfo />
//             </TabPanel>
//<Tab label="设备注册" {...a11yProps(0)} />
//                     <Tab label="获取授权" {...a11yProps(1)} />
//                     <Tab label="影片信息" {...a11yProps(2)} />
//
