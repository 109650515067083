import './App.css';
import React from "react";
import BasicTabs from "./components/TabPanel";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/login";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/tabs" element={<BasicTabs />} />
                <Route path="/" element={<Login />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}


export default App;










