import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import renderCellExpand from "./RenderCellExpand";
import axios from 'axios';
import * as https from "https";
import Snackbar from '@mui/material/Snackbar';
import config from '../config/config.js';
import history from './History';
import cookie from "react-cookies";

const API_URL =  global.AppConfig.HOST+'/response/registers';

const columns: GridColDef[] = [
    { field: 'id', headerName: '传输ID', width: 100},
    { field: 'time', headerName: '请求接收时间', width: 200},
    { field: 'csr', headerName: 'CSR', width: 700, sortable: false, renderCell: renderCellExpand},
    { field: 'deviceId', headerName: '设备唯一标识id', width: 400, renderCell: renderCellExpand},
    { field: 'state', headerName: '状态', width: 150 },
    { field: 'code', headerName: '错误代码', width: 150 },
    { field: 'msg', headerName: '错误文字描述', width: 150, sortable: false, renderCell: renderCellExpand },
    { field: 'license', headerName: '证书', width: 700, sortable: false, renderCell: renderCellExpand},
    { field: 'reply', headerName: '原始回复', width: 700, sortable: false, renderCell: renderCellExpand }
];

const errorMessageMap = new Map();
errorMessageMap.set('Network Error', '连接业务服务器失败，请联系管理员检查业务服务是否正常运行');

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            snackbarOpen: false,
            message: '',
            errMsg: '',
        }
    }

    componentDidMount() {
        axios.get(API_URL, { headers:{
            "Access-Control-Allow-Origin": true,
//            "Access-Control-Allow-Credentials": true,
            "Authorization":"Bearer "+cookie.load("token")
            }, httpsAgent: new https.Agent({rejectUnauthorized: false})
        }).then(response => {
            this.setState({rows: response.data})
        }).catch(
        err => {
            this.setState({snackbarOpen:true, message: err.message + ': ' + errorMessageMap.get(err.message)});
            console.log("Network Error:" + err.message);
            console.log("Error.response.status:" + err.response.status);
            console.log(JSON.stringify(err));
                if (err.response.status === 403) {
                    cookie.remove("token");
                    history.push("/");
                    history.go();
                }
            }
        );
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false });
    };

    render() {
        console.log("设备管理返回:" + JSON.stringify(this.state.rows))
        return (
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={this.state.rows}
                    columns={columns}
                    autoHeight
                />
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
                open={this.state.snackbarOpen}
                onClose={this.handleCloseSnackbar} message={this.state.message}
              />

            </div>
        );
    }
}
export default Register;

